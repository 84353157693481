* {
  box-sizing: border-box;
}

:root {
  --color-primary: hsl(212, 100%, 48%);
  --color-primary-light: hsl(212, 100%, 55%);
  --color-primary-dark: hsl(212, 100%, 40%);
  --color-secondary: hsl(0, 0%, 95%);
  --color-secondary-light: hsl(0, 0%, 98%);
  --color-secondary-dark: hsl(0, 0%, 90%);
  --color-bg: hsl(0, 0%, 20%);
  --color-fg: hsl(0, 0%, 95%);

  font: 14px/1.5 sans-serif;
}

body {
  background: var(--color-bg);
  color: var(--color-fg);
  padding: 0;
  margin: 0;
}

button {
  background: var(--color-secondary);
  border: 0.1rem solid var(--color-bg);
  color: var(--color-bg);
  border: none;
  border-radius: 0.3rem;
  padding: 0.6rem;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.1s;
}

button:hover {
  background: var(--color-secondary-light);
}

button:active {
  background: var(--color-secondary-dark);
}

button.primary {
  background: var(--color-primary);
  color: var(--color-fg);
  border: 0.1rem solid var(--color-primary-dark);
}

button.primary:hover {
  background: var(--color-primary-light);
}

button.primary:active {
  background: var(--color-primary-dark);
}

button.close {
  /* Modal close button, should be perfectly circular */
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  padding: 0;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.actions > * {
  margin-right: 1rem;
}

.actions > *:last-child {
  margin-right: 0;
}

.birds {
  /* big jumbotron with picture */
  width: 100vw;
  height: min(100vw, 80vh);
  /* blue gradient with image on top */
  background: url('images/heron-crow.webp') no-repeat center center,
    linear-gradient(to bottom, hsl(212, 100%, 75%), hsl(212, 100%, 60%));
  background-size: contain;
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 1rem;
  font-size: 3rem;
  font-weight: bold;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.modal-content {
  width: 80%;
  max-width: 35rem;
  background: var(--color-bg);
  padding: 1rem;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
}

.modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 100%;
}

.modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  flex: 1;
  margin: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.something {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('images/something-overlay.webp') no-repeat center center;
  background-size: cover;
  mask-image: radial-gradient(circle, #00000020 0%, black 50%);
  -webkit-mask-image: -webkit-radial-gradient(circle, #00000020 0%, black 50%);
  pointer-events: none;
  z-index: 110;
  opacity: 0;
  animation: fadeIn 30s linear forwards;
}
